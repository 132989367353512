import React, { Suspense } from "react";
// import { Route, MemoryRouter as Router, Routes } from "react-router-dom";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PageLoading from "./components/spinners/PageLoading";
import OutletWithProvider from "./context/OutletWithProvider";

const PageNotFound = React.lazy(() => import("./pages/auth/PageNotFound"));
const Login = React.lazy(() => import("./pages/auth/Login"));
const Wrapper = React.lazy(() => import("./pages/auth/Wrapper"));
const ForgotPassword = React.lazy(() => import("./pages/auth/ForgotPassword"));
const ResetPasswordOtp = React.lazy(
  () => import("./pages/auth/ResetPasswordOtp")
);

const Dashboard = React.lazy(() => import("./pages/overview/Dashboard"));
const Organizations = React.lazy(
  () => import("./pages/organizations/Organizations")
);
const OrganizationDetails = React.lazy(
  () => import("./pages/organizations/OrganizationDetails")
);
const Members = React.lazy(() => import("./pages/members/Members"));
const Subscriptions = React.lazy(
  () => import("./pages/subscriptions/Subscriptions")
);
const SubscriptionPlans = React.lazy(
  () => import("./pages/subscriptions/SubscriptionPlans")
);

const Administration = React.lazy(() => import("./pages/admin/Administration"));
const OnlinePaymentRequests = React.lazy(
  () => import("./pages/online-payment-request/OnlinePaymentRequest")
);
const Payouts = React.lazy(() => import("./pages/payouts/Payouts"));

function App() {
  return (
    <Suspense fallback={<PageLoading />}>
      <Router>
        <Routes>
          <Route path="/*" element={<PageNotFound />} />
          <Route path="/" element={<Wrapper />} />
          <Route path="/signin" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPasswordOtp />} />

          {/* Private route  */}
          <Route element={<OutletWithProvider />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/online-payment-requests"
              element={<OnlinePaymentRequests />}
            />
            {/* <Route
              path="/online-payment-requests/:requestId"
              element={<OnlinePaymentRequests />}
            /> */}
            <Route path="/organizations" element={<Organizations />} />
            <Route path="/subscriptions" element={<Subscriptions />} />
            <Route path="/subscription-plan" element={<SubscriptionPlans />} />
            <Route path="/members" element={<Members />} />
            <Route path="/payout-history" element={<Payouts />} />
            <Route path="/administration" element={<Administration />} />
            <Route
              path="/organizations/:organizationId"
              element={<OrganizationDetails />}
            />
          </Route>
        </Routes>
      </Router>

      <ToastContainer />
    </Suspense>
  );
}

export default App;
